@import '~/styles/styles';

.header-main {
  display: grid;
  place-content: center;
  padding: 50px 0;

  @include mobile {
    padding: 20px 0;
  }
}

.header-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
}

.copy {
  display: flex;
  flex-direction: column;
  place-content: center;
  color: $white;
  gap: 20px;

  button {
    align-self: center;

    @include mobile {
      margin: 30px 0 0;
      width: 100%;
    }
  }

  h1,
  h2,
  h3 {
    text-align: center;
    margin: 5px 0;
  }
}

@include mobile {
  .header-container {
    grid-gap: 30px;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;

    section {
      order: 2;
      height: 100%;
      width: 100%;
      position: relative;
    }

    .title-text,
    h2,
    p,
    .countdown {
      max-width: 100vw;
      @include custom-breakpoint(370) {
        font-size: 1em;
      }
    }

    .copy {
      order: 1;
      gap: 5px;
      z-index: 1;

      .countdown {
        place-content: center;
      }

      button {
        max-width: 60%;
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.title-text {
  display: grid;
}

.countdown {
  display: grid;
  padding: 0 20%;
}

.banner {
  background-image: url($seriesThreeBannerDesktopBackground);
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  background-origin: content-box;

  @include mobile {
    background-image: unset;
    background-position: unset; /* Center the image */
    background-repeat: unset; /* Do not repeat the image */
    background-size: unset; /* Resize the background image to cover the entire container */
    background-origin: unset;
    background: black;
    z-index: 0;
    max-width: 100%;

    display: grid;
    place-content: center;
    margin-bottom: -25%; /* needed to pull the text up since the pic has a tall black bottom*/
    img {
      max-height: 100vw;
    }
  }
}
