@import '~/styles/styles';

.countdown-container {
  display: grid;
  grid-auto-flow: row;
  margin: 10px 0;

  @include mobile {
    width: calc(100% - 2px);
  }

  div {
    div {
      flex-direction: column;
    }
  }

  .heading {
    font-size: 24px;
    font-weight: 900;
    margin: 0 0 20px;

    &.no-margin {
      margin: 0;
    }

    @include mobile {
      font-size: 22px;
    }
  }

  .number-container {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;
    position: relative;
    isolation: isolate;

    @include mobile {
      gap: 10;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    div {
      display: grid;
      justify-items: center;
      text-transform: uppercase;
      font-size: 12px;
      position: relative;

      @include mobile {
        width: auto;
        font-size: 11px;
      }

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 85%;
        background: white;
        position: absolute;
        right: -15px;
        z-index: 1000;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    .number {
      font-size: 20px;
      font-weight: 900;
    }
  }
}
.shop-now {
  color: white;
  -webkit-text-fill-color: white;
}

.pack-type-container {
  max-width: 50%;
  @include mobile {
    max-width: 100%;
  }
}
