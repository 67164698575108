@import '~/styles/styles';

.roadmap-container {
  display: grid;
  padding: 80px 30px;
  margin-top: 60px;
  @include background-img($backgroundBigGlitch);

  &::before {
    content: '';
    display: block;
    width: 60vw;
    height: 1px;
    background-color: $white;
    margin: -90px auto 0;
  }
}

.copy-container {
  text-align: center;

  h2 {
    &::after {
      content: '';
      width: 60px;
      height: 1px;
      display: block;
      margin: 0 auto;
      background: $white;
      margin-bottom: 15px;
      margin: 8px auto;
    }
  }

  h3 {
    margin-top: 25px;
  }

  li {
    margin: 20px 0;
  }
}
